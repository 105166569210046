// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-msquare-jsx": () => import("./../../../src/pages/msquare.jsx" /* webpackChunkName: "component---src-pages-msquare-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../../../src/templates/BlogList.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-work-item-jsx": () => import("./../../../src/templates/WorkItem.jsx" /* webpackChunkName: "component---src-templates-work-item-jsx" */),
  "component---src-templates-work-list-jsx": () => import("./../../../src/templates/WorkList.jsx" /* webpackChunkName: "component---src-templates-work-list-jsx" */)
}

